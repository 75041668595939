import * as React from "react";
import GseBurger from "./Burger/GseBurger";

const GseHeader = () => {
  return (
    <header class="bg-gradient-to-r  from-blue-800 to-purple-800">
      <nav class="max-w-7xl mx-auto px-4 sm:px-6" aria-label="Top">
        <div class="w-full py-4 flex items-center justify-between border-b border-indigo-500 lg:border-none">
          <div class="flex items-center">
            <a href="/">
              <span class="sr-only">Consult FGC</span>
              <img
                class="h-20 w-auto"
                src="https://imgix.cosmicjs.com/b6cf2a70-daa2-11ec-bb19-d9085ce408df-Consult-logo-white.svg"
                alt=""
              />
            </a>
          </div>

          <button
            type="button"
            class="ml-5 inline-flex items-center justify-center rounded-md text-white hover:text-gray-200"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <GseBurger />
          </button>
        </div>{" "}
      </nav>
    </header>
  );
};

export default GseHeader;
