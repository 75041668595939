import * as React from "react";
import GseHeader from "../../components/GseHeader";

const gsePartnership = () => {
  return (
    <div>
      <GseHeader />
      <div class="max-w-7xl mx-auto mb-24">
        <img
          class="py-4 w-3/4 mx-auto"
          src="https://imgix.cosmicjs.com/1fac8f20-f7d5-11ec-8f84-3f01a4a22fe2-GSE-partnership-lockup-long.svg"
        />
        <div class="border w-1/2 mx-auto opacity-50" />
        <div class="max-w-3xl mx-auto text-center">
          <h1 class="text-4xl font-bold text-blue-800 sm:mt-5 sm:text-7xl lg:mt-12">
            Custom GSA
            <div class="mt-2">Targeted Websites</div>
          </h1>
          <p class="mt-3 text-base text-gray-600 sm:mt-8 sm:text-xl lg:text-lg xl:text-xl">
            Consult FGC and Government Services Exchange are pleased to add even
            more value to your government marketing with these GSA Targeted
            WordPress Website Themes. Consult FGC is our dedicated partner that
            specializes in designing these GSA websites for our customers.
          </p>{" "}
        </div>
      </div>
      <div class="wordpress features bg-gradient-to-tl  from-blue-600 to-purple-800 ">
        <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
          <div class="max-w-3xl mx-auto text-center">
            <h2 class="text-base font-semibold text-white uppercase tracking-wide">
              Government Services Exchange
            </h2>
            <p class="mt-2 text-5xl font-extrabold text-white">
              Government Themed Websites
            </p>
            <p class="mt-4 text-lg text-white">
              Your custom Wordpress website will be tailored to the brand
              identity of your business and include all the features detailed
              here.
            </p>
          </div>
          <dl class="mt-20 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-3 lg:gap-x-8">
            <div class="relative">
              <dt>
                <svg
                  class="absolute h-6 w-6 text-green-200"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <p class="ml-9 text-lg leading-6 font-medium text-yellow-200">
                  3-5 Page Custom Wordpress Website
                </p>
              </dt>
              <dd class="mt-2 ml-9 text-base text-white">
                Includes installation, customization, and image editing.
              </dd>
            </div>{" "}
            <div class="relative">
              <dt>
                <svg
                  class="absolute h-6 w-6 text-green-200"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <p class="ml-9 text-lg leading-6 font-medium text-yellow-200">
                  Customer Service
                </p>
              </dt>
              <dd class="mt-2 ml-9 text-base text-white">
                Phone and Email Support during business hours.
              </dd>
            </div>{" "}
            <div class="relative">
              <dt>
                <svg
                  class="absolute h-6 w-6 text-green-200"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <p class="ml-9 text-lg leading-6 font-medium text-yellow-200">
                  Let Us Do The Work
                </p>
              </dt>
              <dd class="mt-2 ml-9 text-base text-white">
                We handle all domain + hosting setup via Godaddy.
              </dd>
            </div>
            <div class="relative">
              <dt>
                <svg
                  class="absolute h-6 w-6 text-green-200"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <p class="ml-9 text-lg leading-6 font-medium text-yellow-200">
                  Responsive Design
                </p>
              </dt>
              <dd class="mt-2 ml-9 text-base text-white">
                Desktop and mobile version will be provided.
              </dd>
            </div>
            <div class="relative">
              <dt>
                <svg
                  class="absolute h-6 w-6 text-green-200"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <p class="ml-9 text-lg leading-6 font-medium text-yellow-200">
                  GSA Optimized
                </p>
              </dt>
              <dd class="mt-2 ml-9 text-base text-white">
                Your new GSA website will be designed to target those GSA
                buyers.
              </dd>
            </div>
            <div class="relative">
              <dt>
                <svg
                  class="absolute h-6 w-6 text-green-200"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <p class="ml-9 text-lg leading-6 font-medium text-yellow-200">
                  We'll Be Done Before You Know It
                </p>
              </dt>
              <dd class="mt-2 ml-9 text-base text-white">
                Quick Turnaround (1-3 Weeks)
              </dd>
            </div>{" "}
            <div class="relative">
              <dt>
                <svg
                  class="absolute h-6 w-6 text-green-200"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <p class="ml-9 text-lg leading-6 font-medium text-yellow-200">
                  Domain Name
                </p>
              </dt>
              <dd class="mt-2 ml-9 text-base text-white">
                Get your personalized domain name or bring your own.
              </dd>
            </div>
            <div class="relative">
              <dt>
                <svg
                  class="absolute h-6 w-6 text-green-200"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <p class="ml-9 text-lg leading-6 font-medium text-yellow-200">
                  Hosting
                </p>
              </dt>
              <dd class="mt-2 ml-9 text-base text-white">
                Optional hosting with dedicated support and 2 hours of design
                updates monthly.
              </dd>
            </div>
            <div class="relative">
              <dt>
                <svg
                  class="absolute h-6 w-6 text-green-200"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <p class="ml-9 text-lg leading-6 font-medium text-yellow-200">
                  Copywriting
                </p>
              </dt>
              <dd class="mt-2 ml-9 text-base text-white">
                Too busy to write-up all your services and company information,
                don’t worry optional copywriting is available as well.
              </dd>
            </div>{" "}
          </dl>
          <div class="mt-24 text-center mx-auto">
            <a
              href="https://governmentservicesexchange.com/"
              target="blank"
              class=" text-white border-2  border-white font-medium  text-xl px-20 py-4 hover:bg-white hover:border-transparent hover:text-blue-700 focus:outline-none"
            >
              Questions? Contact a GSA Specialist
            </a>
          </div>
        </div>
      </div>

      <div class="add-ons bg-gray-100 pb-20 ">
        <div class="max-w-7xl mx-auto">
          <div class="pt-16 pb-16 max-w-3xl mx-auto text-center">
            <h2 class="text-base font-semibold text-gray-500 uppercase tracking-wide">
              Government Services Exchange
            </h2>
            <p class="mt-2 text-5xl font-extrabold text-gray-800">
              GSE Add-On Services
            </p>
          </div>
          <div class=" divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-3 sm:gap-8">
            <div class="col-span-3 rounded-lg shadow relative bg-white p-6 ">
              <div>
                <span class=" inline-flex p-3 bg-teal-50 text-teal-700 ring-4 ring-white">
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M22 10h-1v-2h-11v13h5v1.617c0 .524.121 1.058.502 1.383h-5.002c-.398 0-.779-.158-1.061-.439-.281-.282-.439-.663-.439-1.061v-15c0-.398.158-.779.439-1.061.282-.281.663-.439 1.061-.439h10c.398 0 .779.158 1.061.439.281.282.439.663.439 1.061v2.5zm2 2.25c0-.69-.56-1.25-1.25-1.25h-5.5c-.69 0-1.25.56-1.25 1.25v10.5c0 .69.56 1.25 1.25 1.25h5.5c.69 0 1.25-.56 1.25-1.25v-10.5zm-15.407 11.75h-6.593l2.599-3h3.401v1.804c0 .579.337 1.09.593 1.196zm11.407-1c-.553 0-1-.448-1-1s.447-1 1-1c.552 0 .999.448.999 1s-.447 1-.999 1zm3-3v-6.024h-6v6.024h6zm-2-15h-2v-3h-17v15h6v2h-8v-19h21v5zm-.5 7h-1c-.276 0-.5.224-.5.5s.224.5.5.5h1c.275 0 .5-.224.5-.5s-.225-.5-.5-.5z"
                    />
                  </svg>
                </span>
              </div>
              <div class="mt-8">
                <h3 class="text-lg font-medium">
                  <span class="absolute inset-0" aria-hidden="true"></span>
                  Hosting
                </h3>
                <p class="mt-2 text-sm text-gray-500">
                  Consult FGC has partnered with GoDaddy™ to offer their managed
                  wordpress hosting services. Your GSE Themed website includes:
                </p>
                <p class="ml-4 mt-4 text-sm text-gray-700 font-medium">
                  ➊ 99.9% Uptime -{" "}
                  <span class="font-normal text-gray-500">
                    Your website stays live on the internet night and day
                  </span>
                </p>
                <p class="ml-4 mt-4 text-sm text-gray-700 font-medium">
                  ➋ 24/7/365 Monitoring -{" "}
                  <span class="font-normal text-gray-500">
                    GoDaddy tech-guru’s are monitoring your site consistently to
                    ensure that your site is working properly
                  </span>
                </p>
                <p class="ml-4 mt-4 text-sm text-gray-700 font-medium">
                  ➌ Email and Phone Support (M-F/9-5) -{" "}
                  <span class="font-normal text-gray-500">
                    Have questions, want to talk expansion, we’re here for you
                  </span>
                </p>
                <p class="ml-4 mt-4 text-sm text-gray-700 font-medium">
                  ➍ 30 GB of Storage -{" "}
                  <span class="font-normal text-gray-500">
                    Tons of space for your website
                  </span>
                </p>
                <p class="ml-4 mt-4 text-sm text-gray-700 font-medium">
                  ➎ Daily Backups -{" "}
                  <span class="font-normal text-gray-500">
                    Should something happen, rest assured that we’ve got a
                    back-up of your website that can be quickly re-launched.
                  </span>
                </p>
                <p class="ml-4 mt-4 text-sm text-gray-700 font-medium">
                  ➏ Free Email with 1 Yr. Hosting & Domain Package -{" "}
                  <span class="font-normal text-gray-500">
                    LikeABoss@YourCompanyName.com, yeah you could get that email
                    if you want or maybe something else?
                  </span>
                </p>
                <p class="max-w-4xl ml-4 mt-4 text-sm text-gray-700 font-medium">
                  ❼ 2 Hrs. FREE Design Time (Monthly) -{" "}
                  <span class="font-normal text-gray-500">
                    Consult FGC also offers all GSE customers up-to 2 Hrs. of
                    design time monthly, use it or lose it. Our dedicated US
                    based in-house support team is here for you when you need.
                  </span>
                </p>
              </div>
            </div>{" "}
            <div class="rounded-lg shadow relative bg-white p-6 ">
              <div>
                <span class="inline-flex p-3 bg-purple-50 text-purple-700 ring-4 ring-white">
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.927 23.84c-.638.105-1.292.16-1.959.16-6.606 0-11.968-5.377-11.968-12s5.362-12 11.968-12c6.605 0 11.967 5.377 11.967 12 0 .671-.055 1.329-.161 1.971l-1.917-.675c.055-.424.084-.857.084-1.296 0-5.519-4.469-10-9.973-10-5.505 0-9.973 4.481-9.973 10s4.468 10 9.973 10c.45 0 .894-.03 1.328-.088l.631 1.928zm-1.173-10.001c-.242.103-.507.161-.786.161-1.101 0-1.995-.896-1.995-2s.894-2 1.995-2c1.1 0 1.994.896 1.994 2 0 .211-.032.414-.093.604l9.733 3.426-3.662 1.926 4.06 4.173-1.874 1.827-4.089-4.215-2.167 3.621-3.116-9.523zm-.093 6.131c-.229.02-.46.03-.693.03-4.404 0-7.979-3.585-7.979-8s3.575-8 7.979-8c4.403 0 7.978 3.585 7.978 8 0 .207-.008.412-.023.615l-.979-.344.005-.271c0-3.863-3.128-7-6.981-7-3.853 0-6.982 3.137-6.982 7s3.129 7 6.982 7l.372-.01.321.98zm-.975-2.978c-2.621-.146-4.705-2.327-4.705-4.992 0-2.76 2.234-5 4.987-5 2.605 0 4.747 2.008 4.967 4.564l-1.06-.373c-.374-1.821-1.982-3.191-3.907-3.191-2.202 0-3.99 1.792-3.99 4 0 1.996 1.461 3.652 3.368 3.952l.34 1.04z"
                    />
                  </svg>
                </span>
              </div>
              <div class="mt-8">
                <h3 class="text-lg font-medium">
                  <span class="absolute inset-0" aria-hidden="true"></span>
                  Search Engine Optimization (SEO):
                </h3>
                <p class="mt-2 text-sm text-gray-500">
                  Want your website to organically rank well when someone is
                  searching for your products or services? We’ll that’s exactly
                  that SEO does and Consult FGC does it very very well! SEO is a
                  somewhat complicated process that involves both in-page
                  optimization as well as off-site backlink work that is done
                  over weeks and months. Once the process is completed though
                  your website pages will rank within the first page of Google
                  results for your targeted keyword terms and phrases.
                </p>
              </div>
            </div>{" "}
            <div class="rounded-lg shadow relative bg-white p-6 ">
              <div>
                <span class="inline-flex p-3 bg-sky-50 text-sky-700 ring-4 ring-white">
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M24 3.875l-6 1.221 1.716 1.708-5.351 5.358-3.001-3.002-7.336 7.242 1.41 1.418 5.922-5.834 2.991 2.993 6.781-6.762 1.667 1.66 1.201-6.002zm0 17.125v2h-24v-22h2v20h22z"
                    />
                  </svg>
                </span>
              </div>
              <div class="mt-8">
                <h3 class="text-lg font-medium">
                  <span class="absolute inset-0" aria-hidden="true"></span>
                  Reputation Repair:
                </h3>
                <p class="mt-2 text-sm text-gray-500">
                  Nothing says “don’t even contact me” better than 2 Stars and
                  under in Google reviews. We’ve all dealt with those customers
                  that are trying at best but to keep customers from giving you
                  bad reviews involve both a standard in-house review processes
                  AND delivering good service to those existing customers.
                  Negative reviews can only be dealt with by positive reviews
                  and Consult FGC can help you with getting more positive
                  reviews from your customer base, thus improving your
                  reputation within Google.
                </p>
              </div>
            </div>
            <div class="rounded-lg shadow relative bg-white p-6 ">
              <div>
                <span class=" inline-flex p-3 bg-yellow-50 text-yellow-700 ring-4 ring-white">
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"
                    />
                  </svg>
                </span>
              </div>
              <div class="mt-8">
                <h3 class="text-lg font-medium">
                  <span class="absolute inset-0" aria-hidden="true"></span>
                  Social Media Marketing:
                </h3>
                <p class="mt-2 text-sm text-gray-500">
                  What social media platform(s) should you be marketing on? The
                  process of creating unique, captivating, and click-worthy
                  content for your brand is time consuming and spotty at best
                  for most companies. Consult FGC has in-house content creators
                  and copywriters that can take over this process and create
                  unique, click-worthy content that generates interest in your
                  business. We also have in-house advertising experts to create
                  unique campaigns to directly target and re-target those
                  potential customers.
                </p>
              </div>
            </div>{" "}
            <div class="col-span-3 rounded-lg shadow relative bg-white p-6 ">
              <div>
                <span class="inline-flex p-3 bg-indigo-50 text-indigo-700 ring-4 ring-white">
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M18 0c-3.148 0-6 2.553-6 5.702 0 4.682 4.783 5.177 6 12.298 1.217-7.121 6-7.616 6-12.298 0-3.149-2.852-5.702-6-5.702zm0 8c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm-12-3c-2.099 0-4 1.702-4 3.801 0 3.121 3.188 3.451 4 8.199.812-4.748 4-5.078 4-8.199 0-2.099-1.901-3.801-4-3.801zm0 5.333c-.737 0-1.333-.597-1.333-1.333s.596-1.333 1.333-1.333 1.333.596 1.333 1.333-.596 1.333-1.333 1.333zm6 5.775l-3.215-1.078c.365-.634.777-1.128 1.246-1.687l1.969.657 1.92-.64c.388.521.754 1.093 1.081 1.75l-3.001.998zm12 7.892l-6.707-2.427-5.293 2.427-5.581-2.427-6.419 2.427 3.62-8.144c.299.76.554 1.776.596 3.583l-.443.996 2.699-1.021 4.809 2.091.751-3.725.718 3.675 4.454-2.042 3.099 1.121-.461-1.055c.026-.392.068-.78.131-1.144.144-.84.345-1.564.585-2.212l3.442 7.877z"
                    />
                  </svg>
                </span>
              </div>
              <div class="mt-8">
                <h3 class="text-lg font-medium">
                  <span class="absolute inset-0" aria-hidden="true"></span>
                  Geofence Advertising:
                </h3>
                <p class="mt-2 text-sm text-gray-500">
                  Our proprietary geofenced advertising platform, Hyloq
                  (pronounced High Lock) was developed over 3 years. Designed to
                  be easy-to-use for non-technically trained people or can be
                  managed by our in-house experts for you. The Hyloq geofencing
                  platform can deliver precise advertising to one or multiple
                  geofences. Our proprietary software ties directly into the
                  marketplace allowing us to deliver ads to places like:
                </p>
                <p class="ml-4 mt-4 text-sm text-gray-700 font-medium">
                  ➊ iHeartRadio (live streaming or podcasts)
                </p>
                <p class="ml-4 mt-4 text-sm text-gray-700 font-medium">
                  ➋ Over the Top (OTT), think Roku, Firestick, or Hulu ads
                </p>
                <p class="ml-4 mt-4 text-sm text-gray-700 font-medium">
                  ➌ Native or in-App Ads (e.g. Fox News App, Words with Friends,
                  Candy Crush, etc.)
                </p>
                <p class="ml-4 mt-4 text-sm text-gray-700 font-medium">
                  ➍ Digital Display (Airports, Bars or Restaurants, Gas
                  Stations, etc.)
                </p>
                <p class="ml-4 mt-4 text-sm text-gray-700 font-medium">
                  ➎ So much more . . .
                </p>
              </div>
            </div>{" "}
            <div class="rounded-lg shadow relative bg-white p-6 ">
              <div>
                <span class="inline-flex p-3 bg-fuchsia-50 text-fuchsia-700 ring-4 ring-white">
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M7 11v2.4h3.97c-.16 1.029-1.2 3.02-3.97 3.02-2.39 0-4.34-1.979-4.34-4.42 0-2.44 1.95-4.42 4.34-4.42 1.36 0 2.27.58 2.79 1.08l1.9-1.83c-1.22-1.14-2.8-1.83-4.69-1.83-3.87 0-7 3.13-7 7s3.13 7 7 7c4.04 0 6.721-2.84 6.721-6.84 0-.46-.051-.81-.111-1.16h-6.61zm0 0 17 2h-3v3h-2v-3h-3v-2h3v-3h2v3h3v2z"
                    />
                  </svg>
                </span>
              </div>
              <div class="mt-8">
                <h3 class="text-lg font-medium">
                  <span class="absolute inset-0" aria-hidden="true"></span>
                  Google Advertising:
                </h3>
                <p class="mt-2 text-sm text-gray-500">
                  Ahhh a tale as old as time. Creating google ads that produce
                  real results in our specialty. Should you create display,
                  text, YouTube, or OTT ads or all of the above? Our in-house
                  experts will develop a results-driven advertising campaign
                  that keeps the cost-per-lead/acquisition down and generate
                  real results regardless of budget.
                </p>
              </div>
            </div>{" "}
            <div class="rounded-lg shadow relative bg-white p-6 ">
              <div>
                <span class="inline-flex p-3 bg-rose-50 text-rose-700 ring-4 ring-white">
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </span>
              </div>
              <div class="mt-8">
                <h3 class="text-lg font-medium">
                  <span class="absolute inset-0" aria-hidden="true"></span>
                  Over-the-top (OTT) Advertising:
                </h3>
                <p class="mt-2 text-sm text-gray-500">
                  Ever considered running 15/30/60 second ads in-between your
                  favorite digital tv shows or a popular digital platform (i.e.
                  Pluto.tv, Hulu, etc.)? Working with our geofenced advertising
                  platform, Hyloq, now you can.
                </p>
              </div>
            </div>{" "}
            <div class="rounded-lg shadow relative bg-white p-6 ">
              <div>
                <span class="inline-flex p-3 bg-green-50 text-green-700 ring-4 ring-white">
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.732 13h1.504s2.32-8.403 2.799-10.263c.156-.605.646-.738.965-.737.319.001.826.224.947.74.581 2.466 3.11 13.908 3.11 13.908s1.597-6.441 1.943-7.891c.101-.425.536-.757 1-.757.464 0 .865.343 1 .707.312.841 1.675 4.287 1.677 4.293h1.591c.346-.598.992-1 1.732-1 1.104 0 2 .896 2 2s-.896 2-2 2c-.741 0-1.388-.404-1.734-1.003-.939-.001-1.856 0-2.266.003-.503.004-.774-.289-.928-.629l-.852-2.128s-1.828 7.367-2.25 8.999c-.153.595-.646.762-.97.758-.324-.004-.847-.198-.976-.783-.549-2.487-2.081-9.369-3.123-14.053 0 0-1.555 5.764-1.936 7.099-.13.454-.431.731-.965.737h-2.268c-.346.598-.992 1-1.732 1-1.104 0-2-.896-2-2s.896-2 2-2c.74 0 1.386.402 1.732 1z"
                    />
                  </svg>
                </span>
              </div>
              <div class="mt-8">
                <h3 class="text-lg font-medium">
                  <span class="absolute inset-0" aria-hidden="true"></span>
                  Digital Radio Advertising:
                </h3>
                <p class="mt-2 text-sm text-gray-500">
                  Now through our Hyloq programmatic advertising platform we are
                  able to offer our customers access directly to the iHeartRadio
                  platform. Our in-house advertising team offers radio ad
                  creation as well as placement to all iHeartRadio programs,
                  including podcasts! Get your ads heard on iHeartRadio.
                </p>
              </div>
            </div>
            <div class="rounded-lg shadow relative bg-white p-6 ">
              <div>
                <span class="inline-flex p-3 bg-blue-50 text-blue-700 ring-4 ring-white">
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M23 19.586l-2.832-2.832c.524-.79.832-1.735.832-2.754 0-2.761-2.239-5-5-5s-5 2.239-5 5 2.239 5 5 5c1.019 0 1.964-.308 2.754-.832l2.832 2.832 1.414-1.414zm-7-2.586c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm7-2v-12h1v12h-1zm-4-7.315v-4.685h2v6.11c-.574-.586-1.251-1.068-2-1.425zm-10-4.685v12h-1v-12h1zm-5 0h2v12h-2v-12zm-4 0h2v12h-2v-12zm17 4.08c-.328-.047-.66-.08-1-.08v-4h1v4.08zm-6 2.03v-6.11h3v4.295c-1.152.344-2.18.977-3 1.815z"
                    />
                  </svg>
                </span>
              </div>
              <div class="mt-8">
                <h3 class="text-lg font-medium">
                  <span class="absolute inset-0" aria-hidden="true"></span>
                  Digital Billboard/Gyms/Airports/Etc.:
                </h3>
                <p class="mt-2 text-sm text-gray-500">
                  Your digital ads shown at airports, gyms, billboards and more
                  all through our Hyloq programmatic platform. No longer are
                  small businesses priced out of these lucrative advertising
                  spaces. Get your ads show local or nationally in geofenced
                  targeted areas.
                </p>
              </div>
            </div>
            <div class="rounded-lg shadow relative bg-white p-6 ">
              <div>
                <span class="inline-flex p-3 bg-amber-50 text-amber-700 ring-4 ring-white">
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M21.155 8.64c-.909 1.519-2.327 3.067-4.097 3.004-.413.706-.852 1.677-1.339 2.803l-1.312.553c.936-2.343 2.231-4.961 3.698-6.994-.67.529-1.746 1.637-2.662 2.783-1.098-1.828-.3-3.691.973-5.179.021.641.359 1.196.601 1.475-.087-.53-.114-1.489.195-2.351.718-.732 1.364-1.271 2.113-1.76-.083.478.08 1.026.262 1.361.024-.49.224-1.43.521-1.84.924-.727 2.332-1.373 3.892-1.495-.081.973-.436 2.575-1.024 3.604-.515.404-1.221.68-1.791.833.493.089 1.031.077 1.494-.001-.269.743-.552 1.428-.998 2.276-.679.468-1.578.732-2.203.825.46.187 1.272.245 1.677.103zm-13.841 3.805l.645.781 4.773-2.791-.668-.768-4.75 2.778zm6.96-.238l-.668-.767-4.805 2.808.645.781 4.828-2.822zm4.679.007c-.421.203-.851.341-1.286.398-.12.231-.246.494-.377.773l.298.342c.623.692.459 1.704-.376 2.239-.773.497-5.341 3.376-6.386 4.035-.074-.721-.358-1.391-.826-1.948-.469-.557-6.115-7.376-7.523-9.178-.469-.6-.575-1.245-.295-1.816.268-.549.842-.918 1.43-.918.919 0 1.408.655 1.549 1.215.16.641-.035 1.231-.623 1.685l1.329 1.624 7.796-4.446c1.422-1.051 1.822-2.991.93-4.513-.618-1.053-1.759-1.706-2.978-1.706-1.188 0-.793-.016-9.565 4.475-1.234.591-2.05 1.787-2.05 3.202 0 .87.308 1.756.889 2.487 1.427 1.794 7.561 9.185 7.616 9.257.371.493.427 1.119.15 1.673-.277.555-.812.886-1.429.886-.919 0-1.408-.655-1.549-1.216-.156-.629.012-1.208.604-1.654l-1.277-1.545c-.822.665-1.277 1.496-1.377 2.442-.232 2.205 1.525 3.993 3.613 3.993.596 0 1.311-.177 1.841-.51l9.427-5.946c.957-.664 1.492-1.781 1.492-2.897 0-.745-.24-1.454-.688-2.003l-.359-.43zm-7.933-10.062c.188-.087.398-.134.609-.134.532 0 .997.281 1.243.752.312.596.226 1.469-.548 1.912l-5.097 2.888c-.051-1.089-.579-2.081-1.455-2.732l5.248-2.686zm2.097 13.383l.361-.905.249-.609-3.449 2.017.645.781 2.194-1.284z" />
                  </svg>
                </span>
              </div>
              <div class="mt-8">
                <h3 class="text-lg font-medium">
                  <span class="absolute inset-0" aria-hidden="true"></span>
                  Copywriting:
                </h3>
                <p class="mt-2 text-sm text-gray-500">
                  By far one of the most complicated and underrated marketing
                  services that most businesses overlook or don’t have the time
                  for. Our in-house copywriters get to know your business and
                  brand and create copy for ads, websites, or any other
                  marketing material you require. We learn about your business
                  and can write in your voice to communicate to your targeted
                  audiences.
                </p>
              </div>
            </div>
            <div class="rounded-lg shadow relative bg-white p-6 ">
              <div>
                <span class="inline-flex p-3 bg-teal-50 text-teal-700 ring-4 ring-white">
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M21.949 11c-.469-4.725-4.224-8.48-8.949-8.95v-2.05h-2v2.05c-4.725.47-8.48 4.225-8.949 8.95h-2.051v2h2.051c.469 4.725 4.224 8.48 8.949 8.95v2.05h2v-2.05c4.725-.469 8.48-4.225 8.949-8.95h2.051v-2h-2.051zm-6.091 2c-.364 1.399-1.459 2.494-2.858 2.858v-1.858h-2v1.858c-1.399-.364-2.494-1.459-2.858-2.858h1.858v-2h-1.858c.364-1.399 1.459-2.494 2.858-2.858v1.858h2v-1.858c1.399.364 2.494 1.459 2.858 2.858h-1.858v2h1.858zm-4.858-8.931v2.021c-2.511.422-4.488 2.399-4.91 4.91h-2.021c.453-3.611 3.32-6.477 6.931-6.931zm-6.931 8.931h2.021c.422 2.511 2.399 4.488 4.91 4.91v2.021c-3.611-.454-6.478-3.32-6.931-6.931zm8.931 6.931v-2.021c2.511-.422 4.488-2.399 4.91-4.91h2.021c-.453 3.611-3.32 6.477-6.931 6.931zm4.91-8.931c-.422-2.511-2.399-4.488-4.91-4.91v-2.021c3.611.454 6.478 3.319 6.931 6.931h-2.021z" />
                  </svg>
                </span>
              </div>
              <div class="mt-8">
                <h3 class="text-lg font-medium">
                  <span class="absolute inset-0" aria-hidden="true"></span>
                  Programmatic Targeting:
                </h3>
                <p class="mt-2 text-sm text-gray-500">
                  Though our Hyloq programmatic advertising platform we can
                  purchase ad-space along multiple mediums, e.g. in Apps,
                  websites, podcasts, radio shows, digital tv, billboards, and
                  much more. Additionally, through our platform we can geotarget
                  audiences with literally thousands of data points, e.g. credit
                  worthiness, car ownership, interests, buying habits, etc. The
                  amount of data that we can capture and market to on media
                  devices is scarry!
                </p>
              </div>
            </div>
            <div class="col-span-3 rounded-lg shadow relative bg-white p-6 ">
              <div>
                <span class="inline-flex p-3 bg-purple-50 text-purple-700 ring-4 ring-white">
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M24 8h-16v16h16v-16zm-18-2v16h1v-15h15v-1h-16zm-2-2v16h1v-15h15v-1h-16zm-2-2v16h1v-15h15v-1h-16zm-2-2v16h1v-15h15v-1h-16z" />
                  </svg>
                </span>
              </div>
              <div class="mt-8">
                <h3 class="text-lg font-medium">
                  <span class="absolute inset-0" aria-hidden="true"></span>
                  Kitchen Sink:
                </h3>
                <p class="mt-2 text-sm text-gray-500">
                  If you consider it marketing or advertising then we can do it.
                  Consider Consult FGC as your outsourced marketing team. From
                  conception, planning, and implementation start to finish we do
                  it all. Our job is to make your look good, to generate leads
                  for your business, and to market your products and services .
                  . . period.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-10 text-center mx-auto">
          <p class="max-w-4xl mx-auto mt-4 text-lg text-blue-900">
            These additional Consult FGC services can be started at any time
            during the design process. For additional information or to discuss
            any of our services in further detail contact us below:
          </p>

          <div class="mt-4 max-w-xl mx-auto border-b pb-3 flex justify-center">
            <div>
              {" "}
              <a
                href="mailto:Gse@consultfgc.com"
                class="pr-4 mx-auto mt-6 font-medium text-xl text-blue-500 hover:underline max-w-4xl"
              >
                Gse@consultfgc.com
              </a>
            </div>
            <div>
              {" "}
              <a
                href="tel:7274772023"
                class="border-l pl-4 mx-auto mt-6 font-medium text-xl text-purple-700 hover:underline max-w-4xl"
              >
                727-477-2023
              </a>
              <p class="inline ml-2 text-gray-500 ">Ext. 118</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default gsePartnership;
