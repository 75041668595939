import React, { useState } from "react";
import StyledBurger from "./StyledBurger";
import GseMenuStyled from "../Menu/GseMenuStyled";
import MenuModal from "../Menu/MenuModal";

const GseBurger = () => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <StyledBurger open={open} setOpen={setOpen} />
      <GseMenuStyled open={open} setOpen={setOpen} />
      <MenuModal open={open} setOpen={setOpen} />
    </div>
  );
};

export default GseBurger;
